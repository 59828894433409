import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private route: ActivatedRoute) {}

  canActivate() {
    console.log('caiu no auth guard');
    const plano = this.authService.getPlanoSelecionado();
    if (localStorage.getItem('isLoggedin') && plano.id) {
      if (environment.medAulaId === null) {
        environment.medAulaId = localStorage.getItem('source');
      }

      console.log('medaula id aqui no auth.guard: ', environment.medAulaId);
      localStorage.setItem('source', environment.medAulaId);
      return true;
    }
    let source = {};
    console.log( this.route.queryParams );
    this.route.queryParams.subscribe((params) => {
      if (!params['source']) {
        console.log( environment.medAulaId );
        source = { source: environment.medAulaId };
        localStorage.setItem('source', environment.medAulaId);
      }
    });
    this.router.navigate(['/login'], { queryParams: source });
    return false;
  }
}
